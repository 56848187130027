import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import vi_meta from './vi/meta.json'
import en_meta from './en/meta.json'
import en_content from './en/content.json'
import vi_content from './vi/content.json'
const resources = {
  en: {
    meta: en_meta,
    content: en_content
  },
  vi: {
    meta: vi_meta,
    content: vi_content
  }
}
let currentLanguage: string
export const getCurrentLanguage = (): string => currentLanguage
export default function createI18n(lng: string): void {
  currentLanguage = lng
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng,
      fallbackLng: false,
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    })
}
