import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import createI18n from '../locales/i18n'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  router?.locale && createI18n(router.locale)
  return <Component {...pageProps} />
}

export default MyApp
